import React, { lazy, Suspense, useEffect, useState } from "react";
import "./home.css";
import { motion } from "framer-motion";
import { slideIn, staggerContainer, textVariant } from "../../utils/motion";
import { Link } from "react-router-dom";
import hopeImg from "../../assets/images/hope.png";
import changeImg from "../../assets/images/circular-image.png";
import hesIconImg from "../../assets/images/web.png";
import eventImg1 from "../../assets/images/events/1.jpg";
import eventImg2 from "../../assets/images/events/2.jpg";
import eventImg3 from "../../assets/images/events/3.jpg";

import { FaHeartPulse } from "react-icons/fa6";
import { Helmet } from "react-helmet";

const WaveSVG = lazy(() => import("../../components/waves"));

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);
const Slider = lazy(() => import("../../components/slider"));
const OurTeam = lazy(() => import("../../components/ourTeam"));
const Testimonials = lazy(() => import("../../components/testimonials"));

const UpcomingEvents = lazy(() => import("../../components/upcoming-events"));
const UpcomingEventsSkeleton = lazy(() =>
  import("../../components/skeletons/UpcomingEventsSkeleton")
);

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Helmet>
        <title> Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <section className="showcase">
        <div className="container">
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className="txtContent showcaseTxtContent"
          >
            <div className="txtHeader">
              <motion.h1 variants={textVariant(1.1)}>
                give the child the gift of education
              </motion.h1>
            </div>

            <motion.div variants={textVariant(1.2)} className="txt">
              <article>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga
                dolores incidunt velit. Voluptatum, mollitia sequi voluptas
                minima modi fugiat fugit. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Praesentium quaerat laboriosam nobis vitae at
                recusandae quod maxime ad eveniet voluptas.
              </article>
            </motion.div>

            <motion.div variants={textVariant(1.3)} className="btnWrapper">
              <Link to={"/donate"} className="btnPrimary">
                Donate
              </Link>
            </motion.div>
          </motion.div>
        </div>

        <WaveSVG />
      </section>

      <section className="aboutSection pageSection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorRed">What we do</h2>
                </div>
                <div className="txt">
                  <article>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Fuga dolores incidunt velit. Voluptatum, mollitia sequi
                    voluptas minima modi fugiat fugit. Lorem ipsum dolor sit
                    amet consectetur adipisicing elit. Voluptatibus placeat,
                    nostrum explicabo quis voluptatum maiores a unde distinctio
                    eum eaque. Lorem ipsum dolor sit amet consectetur,
                    adipisicing elit. Labore quaerat aut odio pariatur facere
                    aspernatur voluptatibus autem modi sunt qui?
                  </article>
                </div>
                <div className="btnWrapper">
                  <Link to={"/about-us"} className="btnPrimary bkgTeal">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imageWrapper">
                <ProgressiveImage
                  lowQualitySrc={hesIconImg.lowQualityImage}
                  highQualitySrc={hesIconImg}
                  alt="what we do"
                  className={"progressiveImage"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="eventsSection bkgLight pageSection"
        style={{ paddingTop: "0" }}
      >
        <div className="container">
          {isLoading ? (
            <>
              <UpcomingEventsSkeleton />
            </>
          ) : (
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <UpcomingEvents />
              </Suspense>
            </>
          )}
        </div>
      </section>

      <section className="hopeSection pageSection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="imageWrapper">
                <ProgressiveImage
                  lowQualitySrc={hopeImg.lowQualityImage}
                  highQualitySrc={hopeImg}
                  alt="you're the hope of others"
                  className={"progressiveImage"}
                />
              </div>
            </div>
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorTeal">you're the hope of others</h2>
                </div>
                <div className="txt">
                  <article>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Fuga dolores incidunt velit. Voluptatum, mollitia sequi
                    voluptas minima modi fugiat fugit. Lorem ipsum dolor sit
                    amet consectetur adipisicing elit. Distinctio neque voluptas
                    ad sequi praesentium sint debitis assumenda commodi et
                    expedita.
                  </article>
                </div>
                <div className="btnWrapper">
                  <Link to={"/donate"} className="btnPrimary bkgRed">
                    Donate
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="changingLives pageSection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorRed">changing lives with knowledge</h2>
                </div>
                <div className="txt">
                  <article>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Fuga dolores incidunt velit. Voluptatum, mollitia sequi
                    voluptas minima modi fugiat fugit. Lorem ipsum dolor sit
                    amet consectetur adipisicing elit. Voluptatibus placeat,
                    nostrum explicabo quis voluptatum maiores a unde distinctio
                    eum eaque.
                  </article>
                </div>
                <div className="btnWrapper">
                  <Link to={"/donate"} className="btnPrimary bkgTeal">
                    Donate
                  </Link>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imageWrapper">
                <ProgressiveImage
                  lowQualitySrc={changeImg.lowQualityImage}
                  highQualitySrc={changeImg}
                  alt={"changing lives with knowledge"}
                  className={"progressiveImage"}
                />
              </div>
            </div>
          </div>
        </div>
        <Slider />
      </section>

      <section className="featuredCampaigns pageSection bkgLight">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="txtHeader">
                <h2>featured campaigns</h2>
              </div>
              <div className="txt">
                <article>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Libero corporis ratione sit enim minima magnam aperiam dolorum
                  consequatur, optio odio. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Quae ipsa quia sunt, pariatur
                  iure magni deserunt tempora incidunt velit! Quo.
                </article>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="eventCards">
              <div className="eventCard">
                <div className="imageSection">
                  <div className="eventImageWrapper">
                    <ProgressiveImage
                      lowQualitySrc={eventImg1.lowQualityImage}
                      highQualitySrc={eventImg1}
                      alt={"featured campaigns"}
                      className={"eventImage"}
                    />
                  </div>
                </div>
                <div className="txtSection">
                  <div className="txtContent">
                    <div className="txtHeader">
                      <h3 className="colorTeal">
                        Akosua Bompata can now boast of a school uniform and
                        desk
                      </h3>
                    </div>
                    <div className="txt">
                      <article>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Commodi, obcaecati.
                      </article>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <button className="btnCorner">+</button>
                  </div>
                </div>
              </div>

              <div className="eventCard">
                <div className="imageSection">
                  <div className="eventImageWrapper">
                    <ProgressiveImage
                      lowQualitySrc={eventImg2.lowQualityImage}
                      highQualitySrc={eventImg2}
                      alt={"featured campaigns"}
                      className={"eventImage"}
                    />
                  </div>
                </div>
                <div className="txtSection">
                  <div className="txtContent">
                    <div className="txtHeader">
                      <h3 className="colorTeal">
                        Students of Yaw Preko M/A can now study without fear of
                        rain
                      </h3>
                    </div>
                    <div className="txt">
                      <article>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Commodi, obcaecati.
                      </article>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <button className="btnCorner ">+</button>
                  </div>
                </div>
              </div>

              <div className="eventCard">
                <div className="imageSection">
                  <div className="eventImageWrapper">
                    <ProgressiveImage
                      lowQualitySrc={eventImg3.lowQualityImage}
                      highQualitySrc={eventImg3}
                      alt={"featured campaigns"}
                      className={"eventImage"}
                    />
                  </div>
                </div>
                <div className="txtSection">
                  <div className="txtContent">
                    <div className="txtHeader">
                      <h3 className="colorTeal">
                        Donated 1000 exercise books to D/A school
                      </h3>
                    </div>
                    <div className="txt">
                      <article>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Commodi, obcaecati.
                      </article>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <button className="btnCorner">+</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="yourDonation pageSection bkgRed">
        <div className="container">
          <div className="txtContent">
            <div className="iconWrapper">
              <FaHeartPulse className="icon" />
            </div>
            <div className="txtHeader">
              <h3>
                Your donation means a lot to them. <br />
                Donate what you can.
              </h3>
            </div>
            <div className="btnWrapper">
              <Link to={"/donate"} className="btnPrimary">
                Donate
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="testimoniesSection pageSection">
        <div className="container">
          <Testimonials />
        </div>
      </section>

      <OurTeam />
    </>
  );
};

export default Home;
