import React, { lazy, useState } from "react";
import "./about-us.css";
import { aboutUsContent, teamMembers } from "../../constants/data";
import { FaEyeLowVision, FaPlaneDeparture } from "react-icons/fa6";
import Accordion from "../../components/accordion";
import MemberGroup from "../../components/MemberGroup";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title> About Us | Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <section className="aboutUsHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>{aboutUsContent.heroText}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutUsPageSection pageSection">
        <div className="container">
          <div className="txtContent">
            <article>{aboutUsContent.aboutSection.aboutTextContent}</article>
          </div>
          <div className="statementsWrapper">
            <div className="hor">
              <div className="ver bkgGrey">
                <div className="txtContent">
                  <div className="iconWrapper">
                    <FaEyeLowVision className="icon" />
                  </div>
                  <div className="txtHeader">
                    <h3>Vision Statement</h3>
                  </div>
                  <div className="txt">
                    <article>
                      {aboutUsContent.aboutSection.visionStatement}
                    </article>
                  </div>
                </div>
              </div>
              <div className="ver bkgYellow">
                <div className="txtContent">
                  <div className="iconWrapper">
                    <FaPlaneDeparture className="icon" />
                  </div>
                  <div className="txtHeader">
                    <h3>Mission Statement</h3>
                  </div>
                  <div className="txt">
                    <article>
                      {aboutUsContent.aboutSection.missionStatement}
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="valuesSection pageSection bkgLight">
        <div className="container">
          <div className="header">
            <h2>Core values</h2>
          </div>
          <div className="values accordionWrapper">
            <Accordion title={aboutUsContent.coreValuesSection.valueHeader1}>
              <div className="accItem">
                {/* <h5 className="accBullet">
                  {aboutUsContent.coreValuesSection.valueHeader1}
                </h5> */}

                <ul className="accList">
                  <li>{aboutUsContent.coreValuesSection.valueTextContent1}</li>
                </ul>
              </div>
            </Accordion>
            <Accordion title={aboutUsContent.coreValuesSection.valueHeader2}>
              <div className="accItem">
                <ul className="accList">
                  <li>{aboutUsContent.coreValuesSection.valueTextContent2}</li>
                </ul>
              </div>
            </Accordion>
            <Accordion title={aboutUsContent.coreValuesSection.valueHeader3}>
              <div className="accItem">
                <ul className="accList">
                  <li>{aboutUsContent.coreValuesSection.valueTextContent3}</li>
                </ul>
              </div>
            </Accordion>
            <Accordion title={aboutUsContent.coreValuesSection.valueHeader4}>
              <div className="accItem">
                <ul className="accList">
                  <li>{aboutUsContent.coreValuesSection.valueTextContent4}</li>
                </ul>
              </div>
            </Accordion>
            <Accordion title={aboutUsContent.coreValuesSection.valueHeader5}>
              <div className="accItem">
                <ul className="accList">
                  <li>{aboutUsContent.coreValuesSection.valueTextContent5}</li>
                </ul>
              </div>
            </Accordion>
          </div>
        </div>
      </section>

      <MemberGroup teamMembers={teamMembers} />
    </>
  );
};

export default AboutUs;
