import slideImg1 from "../assets/images/events/1.jpg";
import slideImg2 from "../assets/images/events/2.jpg";
import slideImg3 from "../assets/images/events/3.jpg";
import slideImg4 from "../assets/images/events/4.jpg";
import slideImg5 from "../assets/images/events/5.jpg";
import slideImg6 from "../assets/images/events/6.jpg";
import slideImg7 from "../assets/images/events/7.jpg";
import slideImg8 from "../assets/images/events/8.jpg";
import slideImg9 from "../assets/images/events/9.jpg";

import malik from "../assets/images/team/Malik.jpg";
import mabel from "../assets/images/team/Mabel.jpg";
import marilyn from "../assets/images/team/marilyn.jpg";
import zabari from "../assets/images/team/zabari1.jpg";
import rich from "../assets/images/team/rich2.jpg";

import defaultImg1 from "../assets/images/default/book-mockup.jpg";
import defaultImg2 from "../assets/images/default/hes-logo-1.jpg";

// import {
//   buildingTogetherImg,
//   getEmpoweredImg,
//   joinUsImg,
//   mediaMembershipImg,
//   supportImg,
//   member1,
//   member2,
//   member3,
//   missionImg,
//   visionImg,
//   valuesImg,
//   volunteerImg,
//   famImg,
//   ceoImg,
//   payMethodIcon1,
//   payMethodIcon2,
//   payMethodIcon3,
//   payMethodIcon4,
//   motheringUpImg,
//   strongerTogetherImg,
//   fitYourLifeImg,
//   mindfulnessServicesImg,
//   TerraImg,
//   upcomingEventImg1,
//   upcomingEventImg2,
//   upcomingEventImg3,
//   upcomingEventImg4,
//   VelteLogo,
//   msImg,
//   acImg,
//   csfImg,
//   efbImg,
//   hcImg,
//   ilImg,
// } from "../assets";

// import img1 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
// import img2 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
// import img3 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
// import img4 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
// import img5 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
// import img6 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";

// import mus1 from "../assets/images/events/mustard-seed/1.jpg";
// import mus2 from "../assets/images/events/mustard-seed/2.jpg";
// import mus3 from "../assets/images/events/mustard-seed/3.jpg";
// import mus4 from "../assets/images/events/mustard-seed/4.jpg";
// import mus5 from "../assets/images/events/mustard-seed/5.jpg";
// import mus6 from "../assets/images/events/mustard-seed/6.jpg";
// import mus7 from "../assets/images/events/mustard-seed/7.jpg";
// import mus8 from "../assets/images/events/mustard-seed/8.jpg";
// import mus9 from "../assets/images/events/mustard-seed/9.JPG";
// import mus10 from "../assets/images/events/mustard-seed/10.JPG";
// import mus11 from "../assets/images/events/mustard-seed/11.JPG";
// import mus12 from "../assets/images/events/mustard-seed/12.JPG";
// import mus13 from "../assets/images/events/mustard-seed/13.JPG";
// import mus14 from "../assets/images/events/mustard-seed/14.JPG";

// import meet2 from "../assets/images/events/mom-meet-2022/DSC_1882.JPG";
// import meet3 from "../assets/images/events/mom-meet-2022/DSC_1883.JPG";
// import meet4 from "../assets/images/events/mom-meet-2022/DSC_1893.JPG";
// import meet5 from "../assets/images/events/mom-meet-2022/DSC_1897.JPG";
// import meet6 from "../assets/images/events/mom-meet-2022/DSC_1908.JPG";
// import meet7 from "../assets/images/events/mom-meet-2022/DSC_1909.JPG";
// import meet8 from "../assets/images/events/mom-meet-2022/DSC_1918.JPG";
// import meet9 from "../assets/images/events/mom-meet-2022/DSC_1933.JPG";
// import meet10 from "../assets/images/events/mom-meet-2022/DSC_1935.JPG";
// import meet11 from "../assets/images/events/mom-meet-2022/DSC_1943.JPG";
// import meet12 from "../assets/images/events/mom-meet-2022/DSC_1944.JPG";
// import meet13 from "../assets/images/events/mom-meet-2022/DSC_2255.JPG";
// import meet14 from "../assets/images/events/mom-meet-2022/DSC_2287.JPG";
// import meet15 from "../assets/images/events/mom-meet-2022/DSC_2145.JPG";
// import meet16 from "../assets/images/events/mom-meet-2022/DSC_2208.JPG";
// import meet17 from "../assets/images/events/mom-meet-2022/DSC_2227.JPG";
// import meet18 from "../assets/images/events/mom-meet-2022/DSC_2289.JPG";
// import meet19 from "../assets/images/events/mom-meet-2022/DSC_2356.JPG";
// import meet20 from "../assets/images/events/mom-meet-2022/DSC_2169.JPG";
// import meet21 from "../assets/images/events/mom-meet-2022/DSC_2096.JPG";

// import ha1 from "../assets/images/events/harry-ainley/1.jpg";
// import ha2 from "../assets/images/events/harry-ainley/2.jpg";
// import ha3 from "../assets/images/events/harry-ainley/3.jpg";
// import ha4 from "../assets/images/events/harry-ainley/4.jpg";
// import ha5 from "../assets/images/events/harry-ainley/5.jpg";
// import ha6 from "../assets/images/events/harry-ainley/6.jpg";

// import ft1 from "../assets/images/events/fitness-2022/1.jpg";
// import ft2 from "../assets/images/events/fitness-2022/2.jpg";
// import ft3 from "../assets/images/events/fitness-2022/3.jpg";
// import ft4 from "../assets/images/events/fitness-2022/4.jpg";
// import ft5 from "../assets/images/events/fitness-2022/5.jpg";
// import ft6 from "../assets/images/events/fitness-2022/6.jpg";
// import ft7 from "../assets/images/events/fitness-2022/7.jpg";

// import acs1 from "../assets/images/events/africa-centre-sbcci/1.jpg";
// import acs2 from "../assets/images/events/africa-centre-sbcci/2.jpg";
// import acs3 from "../assets/images/events/africa-centre-sbcci/3.jpg";
// import acs4 from "../assets/images/events/africa-centre-sbcci/4.jpg";
// import acs5 from "../assets/images/events/africa-centre-sbcci/5.jpg";

// import efb1 from "../assets/images/events/edmonton-food-bank-Dec-2022/20220115_161913.jpg";
// import efb2 from "../assets/images/events/edmonton-food-bank-Dec-2022/20220115_162439.jpg";
// import efb3 from "../assets/images/events/edmonton-food-bank-Dec-2022/20220115_162502.jpg";
// import efb4 from "../assets/images/events/edmonton-food-bank-Dec-2022/38963.JPEG";
// import efb5 from "../assets/images/events/edmonton-food-bank-Dec-2022/38966.JPEG";
// import efb6 from "../assets/images/events/edmonton-food-bank-Dec-2022/39048.JPEG";
// import efb7 from "../assets/images/events/edmonton-food-bank-Dec-2022/39049.JPEG";
// import efb8 from "../assets/images/events/edmonton-food-bank-Dec-2022/43979.JPEG";

// import mm1 from "../assets/images/events/mommy-and-me/Mommy-&-Me.png";

// import mdo1 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(1).jpg";
// import mdo2 from "../assets/images/events/moms-day-out/BeautyPlus_20220730202156333_save.jpg";
// import mdo3 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(2).jpg";
// import mdo4 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(3).jpg";
// import mdo5 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(4).jpg";
// import mdo6 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(5).jpg";
// import mdo7 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(6).jpg";
// import mdo8 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(7).jpg";
// import mdo9 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17.jpg";

// export const homeContent = {
//   showcaseHeader1: "stronger",
//   showcaseHeader2: "together",
//   showcaseHeader3: "about us",
//   showcaseParagraph:
//     " Mesh of mothers foundation was founded in 2021 during COVID 19 pandemic as an organization to bridge the gap and create a positive environment and services to meet the needs of African Canadians mothers and women in Edmonton and Alberta as a whole.",
//   showcaseImg: strongerTogetherImg,
//   motheringHeader: "mothering up!",
//   motheringP1:
//     "Motherhood is challenging \n in itself and feeling like you \n are alone makes it even harder.",
//   motheringP2:
//     "We're a healthy thriving community in which Afro Canadian mothers can gather, grow and learn while reaching their fullest potential in life and motherhood. Here's why you're safe with us!",
//   motheringImg: motheringUpImg,
//   empoweredHeader1: "Get",
//   empoweredHeader2: "empowered",
//   empoweredP1:
//     "From workshops & seminars to support groups and networking events, our diverse programs are dedicated to equipping you!",
//   empoweredP2:
//     "The Mesh of Mothers foundation offers a range of empowering programs designed to uplift and support African Canadian mothers and women in Edmonton and Alberta",
//   empoweredImg: getEmpoweredImg,
//   supportCauseHeader: "support a cause",
//   supportCauseParagraph:
//     "When you donate to Mesh of Mothers, you become a champion of diversity, equality, and social justice. You demonstrate your commitment to creating a more inclusive society—one where Afro-Heritage mothers are seen, heard, and celebrated. Your donation is not just a financial contribution, it is a generous gesture toward helping M.O.M to be “The leading voice of Afro-heritage maternal health.”",
//   supportCauseImg: supportImg,

//   mediaMemHeader: "resources",
//   mediaMemParagraph: `The "M.O.M Resource Hub" is a comprehensive database specially designed to support Black mothers in Canada in their journey of motherhood. It comprises of BIOPOC professionals who understand and are willing to support. The M.O.M Resource Hub serves as a virtual haven, providing an extensive range of valuable resources and connections tailored to the unique needs and experiences of Black mothers. `,
//   mediaMemImg: mediaMembershipImg,

//   ceoSection: {
//     title: "unveiling \n the vision",
//     subTitle: "A word from our Founder",
//     details: `As the proud founder of our Black Mothers Organization, I have a compelling vision burning in my heart. We are not just another organization; we are destined to be” the leading voice for Afro-heritage maternal well-being”.
//       Together, we will bridge the gaps that have separated us for far too long. We will break barriers that have limited our potential, our dreams, and our opportunities. Our voices will be heard, amplified, and respected. Together, we will create a world where every black mother can flourish and be celebrated. `,
//     image: ceoImg,
//   },

//   buildingTogetherHeader: "building \n together",
//   buildingTogetherHeader2: "our partners",
//   buildingTogetherParagraph:
//     "We extend our heartfelt gratitude to our esteemed partners for standing alongside us as we work tirelessly to create a brighter future for Afro heritage mothers and their families. Together, we are a force for positive transformation and empowerment",
//   buildingTogetherImg: buildingTogetherImg,
//   partnerLogos: [
//     [
//       { alt: "Fit Your Life", logo: fitYourLifeImg },
//       { alt: "Africa Centre", logo: acImg },
//     ],
//     [
//       { alt: "Mindfulness Services", logo: mindfulnessServicesImg },
//       { alt: "United Way Certificate", logo: csfImg },
//     ],
//     [
//       { alt: "Terra Centre", logo: TerraImg },

//       { alt: "Edmonton Food Bank", logo: efbImg },
//     ],
//     [
//       { alt: "Velte Technology Solutions", logo: VelteLogo },
//       { alt: "Hello Courage", logo: hcImg },
//     ],
//     [
//       { alt: "The Mustard Seed", logo: msImg },
//       { alt: "It's Logical", logo: ilImg },
//     ],
//   ],
//   joinSectionHeader: "join us!",
//   joinSectionParagraph:
//     "Are You Looking For A Community That Understands You And The Nuances Of Motherhood? If So, You Have Come To The Right Place Mama, Join our community.",
//   joinSectionImg: joinUsImg,

//   successHeader1: "architects of success",
//   successHeader2: "meet the board",
//   members: [
//     {
//       id: 1,
//       info: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis.",
//       image: "",
//       class: "member",
//     },
//     {
//       id: 2,
//       info: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis.",
//       image: "",
//       class: "member reversed",
//     },
//     {
//       id: 3,
//       info: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis.",
//       image: "",
//       class: "member",
//     },
//   ],
// };

// export const aboutContent = {
//   helloContent:
//     "Mesh of Mothers Foundation was founded in 2021 during COVID-19 pandemic as an organization to bridge the gap and create a positive environment and services to meet the needs of African Canadian mothers and women in Edmonton and surrounding areas.",
//   helloContent2: `When the founder Millicent Asiedu became a mother, her outlook on life shifted. Millicent became cognizant of the invisible and visible load afro heritage mothers navigated daily in order to achieve optimal well-being. In addition, she witnessed the strength and resiliency of mothers.
//   Motherhood is challenging and feeling like you are alone makes it even harder. Concurrently, being from a different ethnicity & background creates another layer of barrier while merging the Canadian culture. Minority populations, specifically, mothers of African backgrounds have been ignored/silenced for many years when it comes to narratives surrounding parenting/motherhood. Mesh of Mothers (M.O.M) hopes to create safe places for mothers to congregate, share knowledge and experiences about our challenges and strengths while making new connections. M.O.M is creating a healthy and thriving community. A community whereby Afro-Canadian mothers can connect, grow and learn while reaching their fullest potential in life and motherhood.`,
//   ourLogo:
//     "A representation of strong women who are creating a community is shown with the 3 dots on the top. The 3 letters are interlocked to show unity, alliance, and togetherness. The 3 colors are clear representations of uniqueness and inclusivity. Afro-women from all ethnicity, culture, and religion in one space, under one mission!! come one, come all!",
//   mission:
//     "To provide culturally relevant programs and services to meet the unique needs of afro heritage mothers",
//   vision: "“The leading voice for Afro-heritage maternal wellbeing.”",
//   values: ["Belonging", "Inspiration", "Appreciation", "Compassion", "Pride"],
//   missionImg: missionImg,
//   visionImg: visionImg,
//   valuesImg: valuesImg,
//   architectsSection: {
//     title: "Architects of Success",
//     subtitle: "Meet the Board",
//     members: [
//       {
//         name: "Millicent Asiedu",
//         title: "Executive Director",
//         image: member1,
//         paragraph1:
//           "Millicent is a Social Worker by profession. She holds a Masters of Social Work degree from York University as well as a Bachelor's in Psychology from Trent University. She is employed with the government of Alberta; assisting Edmonton and surrounding communities while supporting individuals in addressing social challenges.",
//         paragraph2: `As the Chief Executive Officer for Mesh of Mothers Foundation Inc. it is no surprise that Millicent is passionate about M.O.M (Mesh Of Mothers) as she’s also a mother of two beautiful children. Millicent's passion and strong advocacy for women and motherhood is quite infectious as you could immediately sense it from conversing with her.

//         Millicent understands the intricate intersectionality between race, gender, and motherhood and how that impacts their well-being. Millicent shares her journey through fertility and motherhood in hopes of inspiring other black women to embrace their present moments regardless of circumstances. Millicent is vehemently excited as she creates and assists by creating a safe space for black mothers to connect and support each other as they navigate through life. These are ways we can increase maternal mental health and overall well-being. “It takes a village to also raise a mother”

//         When Millicent is not engaging and bringing awareness to social issues impacting black mothers, She enjoys spending time with her family. She is also a Sunday school teacher helping children fall in love with God. She also indulges in reading, traveling, music and dancing (though not the best dancer).`,
//       },
//       {
//         name: "Lesley Asiedu",
//         title: "Treasurer",
//         image: member2,
//         paragraph1:
//           "Lesley uses her entrepreneurial and healthcare philanthropic background as the Board Treasurer at Mesh of Mothers Foundation.",
//         paragraph2: `She holds a Bachelor of Applied Science in Public Health and has over 6 years of experience in running a profitable online business. Lesley is extremely passionate about helping Afro-heritage mothers succeed and reach their full potential. Her goal for Mesh of Mothers Foundation is to cultivate meaningful and lasting relationships with Afro-heritage mothers in Edmonton and all over Canada. In Lesley’s spare time, she teaches cooking and baking classes to participants in different settings. She is excited for what the future holds for M.O.M.`,
//       },
//       {
//         name: "Belinda Abudu",
//         title: "Secretary",
//         image: member3,
//         paragraph1:
//           "Belinda Abudu is a passionate psychology graduate who is committed to uplifting her community. With many years of experience in the non-profit sector as a community advocate, she has dedicated her career to improving people's lives. Belinda is exceptional at understanding others and possesses a strong sense of empathy that allows her to connect with people from different backgrounds.",
//         paragraph2: `Her experience in employment services has been invaluable to her community, helping job seekers to identify their strengths and skills to find meaningful work. Belinda believes in continuous learning and seeks ways to improve herself and those around her. Embracing diversity and inclusivity, she recognizes that every person has a unique perspective to offer.`,
//       },
//     ],
//   },
// };

// export const donateContent = {
//   supportText:
//     "Mesh Of Mothers (MoM) is committed to providing a seamless and secure payment process to its clients. The following payment processes and procedures have been developed to ensure that all payments are processed accurately and efficiently.",
//   paymentMethodList: [
//     "Bank Account Transfer",
//     "Cheque",
//     "Email Money Transfer to donate@meshofmothers.ca",
//     "Other payment methods, as agreed upon between MoM and the client.",
//   ],
//   methods: [
//     {
//       title: "Bank Account Transfer",
//       description:
//         "Clients who wish to make payment via bank account transfer should follow these steps:",
//       icon: payMethodIcon1,
//       steps: [
//         "Contact MoM via email or phone to request the necessary bank account details.",
//         "Once the client receives the bank account details, they should initiate the transfer through their bank or online banking system.",
//         "The client should email a copy of the transfer confirmation to MoM at donate@meshofmothers.ca",
//         "Upon receipt of the transfer confirmation, MoM will confirm the receipt of payment and issue an invoice to the client.",
//       ],
//     },
//     {
//       title: "Cheque",
//       description:
//         "Clients who wish to make payment via cheque should follow these steps:",
//       icon: payMethodIcon2,
//       steps: [
//         'Make the cheque payable to "Mesh Of Mothers Foundation Inc".',
//         "Upon receipt of the cheque, MoM will confirm the receipt of payment and issue an invoice to the client.",
//       ],
//     },
//     {
//       title: "Email Money Transfer to",
//       description:
//         "Clients who wish to make payment via email money transfer should follow these steps:",
//       icon: payMethodIcon3,
//       steps: [
//         "Log in to their online banking system and navigate to the email money transfer section.",
//         "Add donate@meshofmothers.ca as a recipient and follow the prompts to complete the transfer.",
//         "In the message section, include the client's name, and any additional details.",
//         "Upon receipt of the transfer, MoM will confirm the receipt of payment and issue an invoice to the client.",
//       ],
//     },
//     {
//       title: "Other payment methods",
//       description:
//         "Clients who wish to make payment via other payment methods should contact MoM to agree on the payment process and procedure.",
//       icon: payMethodIcon4,
//       steps: [
//         "MoM will issue an invoice to the client once payment has been received.",
//         "If payment is not received within the agreed-upon timeframe, MoM will follow up with the client via email or phone to request payment.",
//         "If payment is still not received after two follow-ups, MoM may seek legal action to recover the outstanding amount.",
//       ],
//     },
//   ],
// };

export const programsContent = {
  heroText: "programs",
  familySection: {
    header: "we are \n family",
    subTitle: "Together we can do more",
    textContent: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
    image: defaultImg1,
  },
  volunteerSection: {
    header: "volunteer",
    subTitle: "Extend a helping hand",
    textContent: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
    image: defaultImg2,
  },
};

export const aboutUsContent = {
  heroText: "who we are",
  aboutSection: {
    aboutTextContent: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio.
    
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
    visionStatement: `Empowering young minds to shape a brighter future, where every child has access to quality education and a nurturing environment to thrive.
`,
    missionStatement: `To transform lives by expanding access to superior education, advocating for children's well-being, and championing innovative approaches that address the multifaceted challenges of tomorrow. Our mission is to nurture communities where every child can realize their full potential and contribute to a sustainable and prosperous future.`,
    image: defaultImg1,
  },
  coreValuesSection: {
    valueHeader1: "Child-Centered Commitment",
    valueTextContent1: ` We prioritize the holistic development and well-being of children, ensuring their needs guide every decision we make.
`,
    valueHeader2: "Universal Access to Education",
    valueTextContent2: ` We are dedicated to the principle that high-quality education is an inalienable right, transcending socio-economic, cultural, and geographical boundaries
`,
    valueHeader3: "Innovation-Driven Impact",
    valueTextContent3: ` We harness the power of creativity and forward-thinking collaborations to pioneer solutions that create lasting, positive change.

`,
    valueHeader4: "Empowerment Through Education",
    valueTextContent4: ` We believe in equipping children, families, and communities with the knowledge, skills, and resources necessary to drive their own success and resilience.

`,
    valueHeader5: "Compassionate Action",
    valueTextContent5: ` Our approach is rooted in empathy and kindness, with a steadfast commitment to making a meaningful difference in the lives we touch.
`,
  },
};

export const membershipContent = {
  heroText: "Membership",
  familySection: {
    header: "we are \n family",
    subTitle: "Together we can do more",
    textContent: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
    image: defaultImg1,
  },
  volunteerSection: {
    header: "volunteer",
    subTitle: "Extend a helping hand",
    textContent: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
    image: defaultImg2,
  },
};

const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

export const event = [
  {
    id: "1",
    image: defaultImg1,
    title: "Every child deserves an education",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Kwame Danso",

    date: {
      day: "14",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
    ],
  },
  {
    id: "2",
    image: defaultImg2,
    title: "Making a difference in kids",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Adugyamah",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
  {
    id: "3",
    image: defaultImg1,
    title: "Akosua Bompata can now boast of a school uniform and desk",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Adugyamah",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
  {
    id: "4",
    image: defaultImg2,
    title: "Students of Yaw Preko M/A can now study without fear of rain",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Yaw Preko",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
  {
    id: "4",
    image: defaultImg1,
    title: "Donated 1000 exercise books to D/A school",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Morokrom",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
];

export const events = shuffle(event);

export const upcomingEvents = [
  {
    id: "1",
    image: defaultImg2,
    title: "School for all",
    details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.
      
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.  `,
  },
  {
    id: "2",
    image: defaultImg1,
    title: "Books for all",
    details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.

    Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
  },
];

// export const programsContent = {
//   list: [
//     {
//       title: "Psycho-education and awareness",
//       description:
//         "The Psychoeducation and Awareness Program for Mesh Of Mothers (MoM) is a comprehensive initiative designed to provide mental health education, support, and empowerment for black mothers within the community. This program aims to address the unique challenges and experiences faced by black mothers while promoting their emotional well-being, resilience, and self-care.",
//     },
//     {
//       title: "Maternal Mentorship",
//       description:
//         "The Maternal Mentorship Program for Mesh of Mothers (MoM) is a transformative initiative designed to provide guidance, support, and empowerment to black mothers within the community. This program aims to create a network of experienced mentors who can offer guidance, share knowledge, and serve as role models for black mothers navigating various aspects of motherhood.",
//     },
//     {
//       title: "Resource finding",
//       description:
//         "The Resource Finding Program for Mesh Of Mothers (MoM) is a vital initiative designed to connect black mothers with essential resources, services, and support systems within the community. This program aims to address the unique needs and challenges faced by black mothers by providing them with access to a wide range of resources that promote their well-being, empowerment, and overall success.",
//     },
//     {
//       title: "Mommy & Me programs / parenting programs",
//       description:
//         "The Mommy & Me/Parenting Program for Mesh Of Mothers (MoM) is a valuable initiative designed to provide a supportive and enriching environment for black mothers and their children. This program focuses on nurturing the parent-child bond, enhancing parenting skills, and fostering holistic child development within the black community. ",
//     },
//     {
//       title: "Community innitiatives",
//       description:
//         "The Community Initiatives Program for Mesh Of Mothers (MoM) is a dynamic and inclusive initiative designed to engage and uplift the black mothers in the community. This program aims to foster a sense of belonging, provide opportunities for personal growth, and promote positive social change through various community-based initiatives.",
//     },
//   ],
// };

export const sliderImages = [
  {
    image: slideImg1,
  },
  {
    image: slideImg2,
  },

  {
    image: slideImg3,
  },
  {
    image: slideImg4,
  },
  {
    image: slideImg5,
  },
  {
    image: slideImg6,
  },
  {
    image: slideImg7,
  },
  {
    image: slideImg8,
  },
  {
    image: slideImg9,
  },
  {
    image: slideImg5,
  },
];

export const testimonies = {
  testimonyHeader: "what people say !  ",
  testimonyTitle: "testimonials",
  testimonials: [
    {
      id: "0",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Ruth Akotompaya",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "1",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Asabea Jude",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "2",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Yaw Bompata",
      role: " beneficiary",
      avatar: "",
    },
    {
      id: "3",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Black Moses ",
      role: " Beneficiary",
      avatar: "",
    },
    {
      id: "4",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Hajia Bintuphobia ",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "5",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Jane Doe ",
      role: " Beneficiary",
      avatar: "",
    },
    {
      id: "6",
      testimony: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "John Doe ",
      role: " Beneficiary",
      avatar: "",
    },
  ],
};

export const teamMembers = [
  {
    id: "1",
    name: "Richard Anane Asiamah",
    role: "Founder and Director",
    image: rich,

    info: `Richard Anane Asiamah is the founder and director of the Hestis Foundation, an organization dedicated to advancing educational opportunities for children in Ghana. After working as a teacher in Ghana and now residing in the UK, Richard has witnessed the profound impact that financial and logistical challenges can have on a child's ability to attend school.

Motivated by a deep concern for these vulnerable children who were forced to leave school due to lack of support, Richard established the Hestis Foundation. The organization aims to provide crucial educational assistance to those in need, ensuring that every child in Ghana has the opportunity to pursue their education and achieve their full potential.

Richard's vision is not only to help these children succeed but also to inspire a future generation that will, in turn, support others. Through the Hestis Foundation, Richard is dedicated to fostering a culture of mutual aid and community support, embodying the principle that by helping one another, we can build a brighter future for all.`,
  },
  {
    id: "2",
    name: "Anacetus Zabari Jnr",
    role: "HR Manager",
    image: zabari,

    info: `A creative and innovative leader with expertise in business management, customer engagement, and business development. His vision is to drive human capital development by unlocking the potential of young people, fostering true leadership, education, and entrepreneurship. As an aspiring entrepreneur, educator, and impact speaker, Anacetus is committed to developing innovative solutions to empower communities. His strong communication and relationship management skills, alongside his proficiency in leveraging social media for growth, align perfectly with Hestias Foundation's mission to provide education for underprivileged children, helping them grow and give back to those in similar situations.`,
  },
  {
    id: "3",
    name: "Marilyn Asuamah",
    role: "Director",
    image: marilyn,

    info: `Marilyn Asuamah is a director at the Hestis Foundation, bringing her dedication and expertise as a UK-based nurse and continuing student to the organization. With a deep commitment to the mission of Hestis Foundation, Marilyn plays a crucial role in advancing the charity's goals of providing educational support to children in Ghana.

Her background in nursing and ongoing academic pursuits reflect her passion for service and personal growth. Marilyn's work with the Hestis Foundation aligns with her belief in the transformative power of education and her desire to make a meaningful impact on the lives of children facing educational barriers.

Through her role, Marilyn contributes to the foundation's efforts to ensure that every child in Ghana has access to the education they deserve, fostering a cycle of support and empowerment that extends beyond the classroom.`,
  },

  {
    id: "4",
    name: "Malik Benjamin",
    role: "Program Director",
    image: malik,

    info: `Malik Benjamin is a passionate and driven leader with a BSc in Biological Sciences. Currently serving as the Program Director of Hestias Foundation, he brings exceptional leadership and a deep commitment to empowering underprivileged children. Alongside his role, Malik is the President of the National Service Personnel Association, Tema West Municipal, demonstrating his dedication to community service. Beyond his professional life, he is a creative individual who expresses himself through music and personal growth, always striving for greatness and inspiring those around him.`,
  },
  {
    id: "5",
    name: "Mabel Obour",
    role: "General Secretary",
    image: mabel,

    info: ` As the General Secretary of Hestias Foundation, I am passionate about supporting and uplifting underprivileged children, helping them reach their full potential. Alongside my work as a midwife, I am deeply committed to the foundation's mission. In this role, I ensure the smooth operation of our programs and contribute to the positive impact we strive to make in the lives of the children we serve.`,
  },
  // {
  //   id: "6",
  //   name: "ebenezer tetteh",
  //   role: "social media",
  //   image: memberImg6,

  //   delay: " 3",

  //   info: "Social media team member (Our Desiree)",
  // },
  // {
  //   id: "7",
  //   name: "Alexander Acheampong",
  //   role: "Membership coordinator",
  //   image: memberImg7,

  //   delay: "4",

  //   info: "Membership coordinator (Our Desiree), a Registered General Nurse at the Konfo Anokey Teaching  Hospital(Kumasi-Ghana), and love helping humanity.",
  // },
  // {
  //   id: "8",
  //   name: "Eric Acheampong",
  //   role: "Blogger",
  //   image: memberImg8,

  //   delay: "5",

  //   info: "A blogger and social media activist. \n\n Most often, the greatest legacy one can leave behind is your deeds when you were alive. \n\n Eric realized making someone smile and lifting them from their problems by supporting them in cash or kind is one of the greatest achievements. This informed his decision to join this organization so that collectively we can all put a smile on the faces of our little ones.",
  // },
  // {
  //   id: "9",
  //   name: "godfred d. nketia",
  //   role: "Social Media Manager",
  //   image: memberImg9,

  //   delay: "6",

  //   info: " Social Media Manager (Our Desiree), a blogger with much love for kids.",
  // },
  // {
  //   id: "10",
  //   name: "simon okyere",
  //   role: "Project Team",
  //   image: memberImg10,

  //   delay: "7",

  //   info: " Project Team (Our Desiree), a District manager for Cocoa Processing company(LBC) \n\n It has always been his heart’s desire to get the opportunity to help the needy and believes “our Desiree” gives that platform for a dream come true",
  // },
];
