import React from "react";
import "./donate.css";
import { Helmet } from "react-helmet";

<Helmet>
  <title> Donate | Hestias Foundation</title>
  <meta
    name="description"
    content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
  />
  <meta
    name="keywords"
    content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
  />
</Helmet>;

const Donate = () => {
  return (
    <>
      <section className="donateHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>Donate</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donate;
