import React, { lazy } from "react";
import "./programs.css";
import { programsContent } from "../../constants/data";
import { Helmet } from "react-helmet";

const Accordion = lazy(() => import("../../components/accordion"));

const Programs = () => {
  return (
    <>
      <Helmet>
        <title> Programs | Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <section className="programsHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>{programsContent.heroText}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="programsList pageSection">
        <div className="container">
          <div className="packages">
            {/* <h2>Sponsorship Packages</h2> */}
            <Accordion title="Education for all">
              <div className="accItem">
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>

                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>
            </Accordion>

            <Accordion title="Every child deserves equal opportunity">
              <div className="accItem">
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>

                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>
            </Accordion>

            <Accordion title="Food for all">
              <div className="accItem">
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>

                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>
            </Accordion>

            <Accordion title="Clean water for schools">
              <div className="accItem">
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>

                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> some title</h5>
                <ul className="accList">
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores ipsa est sequi repellendus veritatis magni voluptas
                    optio numquam? Accusamus, repudiandae.
                  </li>
                </ul>
              </div>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default Programs;
