import React, { useState, useEffect, lazy, Suspense } from "react";
import "./events.css";
import { Helmet } from "react-helmet";

const UpcomingEvents = lazy(() => import("../../components/upcoming-events"));
const EventCard = lazy(() => import("../../components/event-card"));
const EventCardSkeleton = lazy(() =>
  import("../../components/skeletons/EventCardSkeleton")
);
const UpcomingEventsSkeleton = lazy(() =>
  import("../../components/skeletons/UpcomingEventsSkeleton")
);
const Slider = lazy(() => import("../../components/slider"));
const Testimonials = lazy(() => import("../../components/testimonials"));

const Events = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title> Events | Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <section className="eventsSection">
        <div className="container">
          <div className="header">
            <h1>Events</h1>
          </div>
          {isLoading ? (
            <>
              <UpcomingEventsSkeleton />
              <EventCardSkeleton />
            </>
          ) : (
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <UpcomingEvents />
                <EventCard />
              </Suspense>
            </>
          )}
        </div>
      </section>

      <section className="testimoniesSection pageSection">
        <div className="container">
          <Testimonials />
        </div>
      </section>
      <Slider />
    </>
  );
};

export default Events;
