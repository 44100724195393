import React, { lazy } from "react";
import "./contact.css";

import { Helmet } from "react-helmet";

const ContactForm = lazy(() => import("../../components/forms/contactForm"));

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title> Contact Us | Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <section className="contactHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>Contact us</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="contactInfoWrapper">
        <div className="container">
          <div className="hor formAndAddress">
            <div className="ver">
              <div className="contactForm">
                <div className="txtContent">
                  <div className="txtHeader">
                    <h2>get in touch</h2>
                  </div>
                  <div className="txt">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Rerum accusamus quaerat temporibus possimus magni
                      molestiae alias corporis tempore ratione iure.
                    </p>
                  </div>
                </div>
                <div className="formWrapper">
                  <ContactForm />
                </div>
              </div>
            </div>
            <div className="ver location">
              <div className="address">
                <div className="txtContent">
                  <div className="txtHeader">
                    <h5>our location</h5>
                  </div>
                  <div className="txt">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Rerum accusamus quaerat temporibus possimus magni
                      molestiae alias corporis tempore ratione iure.
                    </p>
                  </div>
                </div>
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4084402.321492104!2d29.660454625591825!3d1.372202781895549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1771a69f6499f945%3A0x874155ce43014549!2sUganda!5e0!3m2!1sen!2sgh!4v1725250704504!5m2!1sen!2sgh"
                    width="100%"
                    height="600"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
