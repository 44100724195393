import React, { lazy } from "react";
import { Helmet } from "react-helmet";

import "./policy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title> Privacy Policy | Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <section className="policy pt-8">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div className="policyContentHeader">
              <h1>Privacy Policy</h1>
              {/* <small>Last Updated On 29-Dec-2022</small> */}
              {/* <small>Effective Date 28-Dec-2022</small> */}
            </div>
            <div className="policyContent">
              <article>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Aperiam esse quis molestiae accusamus vel, totam cumque ad
                  deleniti optio voluptatibus delectus incidunt? Ad, voluptate.
                  Atque quia nam deleniti distinctio possimus.
                </p>
              </article>
              <article>
                <h4>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Velit, alias!
                </h4>
                <div>
                  <ul>
                    <li>
                      {" "}
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Aperiam esse quis molestiae accusamus vel, totam cumque ad
                      deleniti optio voluptatibus delectus incidunt? Ad,
                      voluptate. Atque quia nam deleniti distinctio possimus.
                    </li>
                    <li>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Aperiam esse quis molestiae accusamus vel, totam cumque ad
                      deleniti optio voluptatibus delectus incidunt? Ad,
                      voluptate. Atque quia nam deleniti distinctio possimus.
                    </li>
                    <li>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Aperiam esse quis molestiae accusamus vel, totam cumque ad
                      deleniti optio voluptatibus delectus incidunt? Ad,
                      voluptate. Atque quia nam deleniti distinctio possimus.
                    </li>
                  </ul>
                </div>
              </article>
              <article>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Aperiam esse quis molestiae accusamus vel, totam cumque ad
                  deleniti optio voluptatibus delectus incidunt? Ad, voluptate.
                  Atque quia nam deleniti distinctio possimus.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
