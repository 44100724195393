import React, { lazy, useEffect, useState } from "react";
import "./membership.css";

import { membershipContent } from "../../constants/data";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
const OurTeam = lazy(() => import("../../components/ourTeam"));

const Modal = lazy(() => import("../../components/modal"));
const MembershipSkeleton = lazy(() =>
  import("../../components/skeletons/MembershipSkeleton")
);
const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

const Membership = () => {
  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <MembershipSkeleton />;
  }
  return (
    <>
      <Helmet>
        <title> Membership | Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <section className="membershipHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>{membershipContent.heroText}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="family">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="content">
                <div className="header">
                  <h1>{membershipContent.familySection.header}</h1>
                </div>
                <div className="txtContent">
                  <article>
                    <h3>{membershipContent.familySection.subTitle}</h3>
                    <p>{membershipContent.familySection.textContent}</p>
                  </article>
                </div>
                <div className="btnHolder">
                  <Link to={"/"} className="btnPrimary bkgTeal">
                    become a member
                  </Link>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imgWrapper">
                <ProgressiveImage
                  lowQualitySrc={
                    membershipContent.familySection.lowQualityImage
                  }
                  highQualitySrc={membershipContent.familySection.image}
                  alt={membershipContent.familySection.header}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="volunteer">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="imgWrapper">
                <ProgressiveImage
                  lowQualitySrc={
                    membershipContent.volunteerSection.lowQualityImage
                  }
                  highQualitySrc={membershipContent.volunteerSection.image}
                  alt={membershipContent.volunteerSection.header}
                />
              </div>
            </div>
            <div className="ver">
              <div className="content">
                <div className="header">
                  <h1>{membershipContent.volunteerSection.header}</h1>
                </div>
                <div className="txtContent">
                  <article>
                    <h3>{membershipContent.volunteerSection.subTitle}</h3>
                    <p>{membershipContent.volunteerSection.textContent}</p>
                  </article>
                </div>
                <div className="btnHolder">
                  <a
                    onClick={() => setOpenModal(true)}
                    className="btnPrimary bkgRed"
                  >
                    become a volunteer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurTeam />
      {openModal && <Modal closeModal={setOpenModal} />}
    </>
  );
};

export default Membership;
